// $red: #c7243a;
// $dark-red: #cf3e51;
$gray: #494545;

#news{
    padding: 24px;
    h1{
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 32px;
        margin-top: 16px;
        text-align: center;

        &:first-child{
            margin-top: 0;
        }
    }
    h2{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 12px;
        margin-top: 16px;

        &:first-child{
            margin-top: 0;
        }
    }

    p{
        font-size: 16px;
        line-height: 1.5;
        color: $gray;

        &:last-child{
            margin-bottom: 0;
        }

        // span{
        //     color: $dark-red;
        //     font-weight: bold;
        // }

        // strong{
        //     font-weight: bold;
        // }

        img{
            max-width: 100%;
            max-height: 50vh;
            margin: 20px auto;
            border: 1px solid #efefef;
            border-radius: 12px;
        }
    }

    ul,
    ol{
        margin: 0;
        margin-top: 4px;
        margin-bottom: 4px;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 20px;
        background-color: #f3f3f3;
        border-radius: 4px;
        // list-style: none;

        li{
            margin: 8px;
            margin-right: 0;
            // &::marker{
            //     background-color: #efefef;
            // }
        }
    }
    a{
        // color: $red;
        color: #38bdf8;
        text-decoration: none;

        &:hover{
            text-decoration: underline;
        }
    }
}