// html,
// body,
// div{
//             user-select: none;
//     -webkit-user-select: none;
//     -webkit-touch-callout: none;
//     -webkit-tap-highlight-color: transparent;
//     // touch-action: manipulation;
// }
// *{
//             user-select: none;
//     -webkit-user-select: none;
//     -webkit-touch-callout: none;
//     -webkit-tap-highlight-color: transparent;
// }
html,
body,
*{
    touch-action: manipulation;
}

button,
button > *{
            user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}
// input,
// span,
// p,
// img{
//             user-select: text;
//     -webkit-user-select: text;
//     -webkit-touch-callout: text;
//     -webkit-tap-highlight-color: text;
// }

.tiptap p{
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
}
.tiptap{
    h1{
        font-weight: 900;
        font-size: 3rem;
    }
    h2{
        font-weight: 800;
        font-size: 2.5rem;
    }
    h3{
        font-weight: 700;
        font-size: 2rem;
    }
    h4{
        font-weight: 600;
        font-size: 1.5rem;
    }
    h5{
        font-weight: 600;
        font-size: 1.2rem;
    }
    ol{
        margin-left: 3rem;
    }
    ul{
        margin-left: 3rem;
    }
}
.persistent-selection{
    background-color: rgba(0, 123, 255, .25);
}
.cc-rich-text-editor .ProseMirror{
    outline: none;
    // border-width: 0;
    h1{
        font-size: 1.6rem;
        margin-top: 16px;
        margin-bottom: 8px;
    }
    h2{
        font-size: 1.4rem;
        margin-top: 12px;
        margin-bottom: 6px;
    }
    h3{
        font-size: 1.2rem;
        margin-top: 8px;
        margin-bottom: 4px;
    }
    h4{
        font-size: 1rem;
        margin-top: 8px;
        margin-bottom: 4px;
    }
    p{
        margin-bottom: 12px;
    }
    img{
        margin-bottom: 8px;
        border-radius: 6px;
    }
}

[contenteditable]{
    -webkit-user-select: text;
            user-select: text;
}

.ProseMirror{
    -webkit-user-select: text;
            user-select: text;
    /* writing-mode: vertical-rl; */
}

// canvas
.canvasTextEditor .ProseMirror{
    outline: none;
    // border-width: 0;
}


/* *{
    touch-action: none;
} */

.editor{
    background-color: #ffffff;
    border: 3px solid #0d0d0d;
    border-radius: .75rem;
    color: #0d0d0d;
    display: flex;
    flex-direction: column;
    max-height: 26rem;

    /* &__header {
        align-items: center;
        background: #0d0d0d;
        border-bottom: 3px solid #0d0d0d;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        display: flex;
        flex: 0 0 auto;
        flex-wrap: wrap;
        padding: 0.25rem;
    }

    &__content {
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 1.25rem 1rem;
        -webkit-overflow-scrolling: touch;
    }

    &__footer {
        align-items: center;
        border-top: 3px solid #0d0d0d;
        color: #0d0d0d;
        display: flex;
        flex: 0 0 auto;
        font-size: 12px;
        flex-wrap: wrap;
        font-weight: 600;
        justify-content: space-between;
        padding: 0.25rem 0.75rem;
        white-space: nowrap;
    } */

    /* Some information about the status */
    &__status{
        align-items: center;
        border-radius: 5px;
        display: flex;

        &::before{
            background: rgba(#0d0d0d, .5);
            border-radius: 50%;
            content: " ";
            display: inline-block;
            flex: 0 0 auto;
            height: .5rem;
            margin-right: .5rem;
            width: .5rem;
        }

        &--connecting::before{
            background: #616161;
        }

        &--connected::before{
            background: #b9f18d;
        }
    }

    &__name{
        button{
            background: none;
            border: none;
            border-radius: .4rem;
            color: #0d0d0d;
            font: inherit;
            font-size: 12px;
            font-weight: 600;
            padding: .25rem .5rem;

            &:hover{
                background-color: #0d0d0d;
                color: #ffffff;
            }
        }
    }
}

/* Give a remote user a caret */
.collaboration-cursor__caret{
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label{
    border-radius: 3px 3px 3px 0;
    color: #0d0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -1px;
    line-height: normal;
    padding: .1rem .3rem;
    position: absolute;
    top: -1.4em;
    user-select: none;
    white-space: nowrap;
}

// table
.tiptap{
    table{
        border-collapse: collapse;
        margin: 0;
        overflow: hidden;
        table-layout: fixed;
        width: 100%;

        td,
        th{
            border: 2px solid #ced4da;
            box-sizing: border-box;
            min-width: 1em;
            padding: 3px 5px;
            position: relative;
            vertical-align: top;

            > *{
                margin-bottom: 0;
            }
        }

        th{
            background-color: #f1f3f5;
            font-weight: bold;
            text-align: left;
        }

        .selectedCell:after{
            background: rgba(200, 200, 255, .4);
            content: "";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
            position: absolute;
            z-index: 2;
        }

        .column-resize-handle{
            background-color: #aaddff;
            bottom: -2px;
            position: absolute;
            right: -2px;
            pointer-events: none;
            top: 0;
            width: 4px;
        }

        p{
            margin: 0;
        }
    }
}

.tableWrapper{
    padding: 1rem 0;
    overflow-x: auto;
}

.resize-cursor{
    cursor: ew-resize;
    cursor: col-resize;
}


// moveable
.moveable-line{
    pointer-events: none;
}
.moveable-line.moveable-direction{
    position: absolute;
    // width: 100px;
    height: 2px !important;
    // background: #44aaff;
    transform-origin: 0px .5px;
}

.moveable-line.moveable-rotation-line{
    // height: 40px !important;
    display: none !important;
    width: 2px !important;
    transform-origin: .5px 39.5px !important;
}

.moveable-control.moveable-direction.moveable-e,
.moveable-control.moveable-direction.moveable-w{
    height: 32px;
    margin-top: -16px;
    margin-left: -8px;
    width: 16px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
}

@media (hover: none) and (any-pointer: coarse){
    .moveable-control.moveable-direction.moveable-e,
    .moveable-control.moveable-direction.moveable-w{
        height: 44px;
        margin-top: -22px;
        margin-left: -11px;
        width: 22px;
        border-radius: 11px;
        background: transparent;
        border-color: transparent;
        box-shadow: none;
        &::before{
            content: "";
            display: block;
            width: 16px;
            height: 32px;
            background-color: #ffffff;
            box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
            border-radius: 8px;
            margin: auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.moveable-control.moveable-direction.moveable-s,
.moveable-control.moveable-direction.moveable-n{
    width: 32px;
    height: 16px;
    margin-left: -16px;
    margin-top: -8px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
}
@media (hover: none) and (any-pointer: coarse){
    .moveable-control.moveable-direction.moveable-s,
    .moveable-control.moveable-direction.moveable-n{
        width: 44px;
        height: 22px;
        margin-left: -22px;
        margin-top: -11px;
        border-radius: 11px;
        background: transparent;
        border-color: transparent;
        box-shadow: none;
        &::before{
            content: "";
            display: block;
            width: 32px;
            height: 16px;
            background-color: #ffffff;
            box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
            border-radius: 8px;
            margin: auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.moveable-control.moveable-direction.moveable-nw,
.moveable-control.moveable-direction.moveable-se,
.moveable-control.moveable-reverse .moveable-direction.moveable-ne,
.moveable-control.moveable-reverse .moveable-direction.moveable-sw{
    width: 24px;
    height: 24px;
    margin-left: -12px;
    margin-top: -12px;
    background: transparent;
    border-color: transparent;
    &::before{
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
        border-radius: 50%;
        margin: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}
@media (hover: none) and (any-pointer: coarse){
    .moveable-control.moveable-direction.moveable-nw,
    .moveable-control.moveable-direction.moveable-se,
    .moveable-control.moveable-reverse .moveable-direction.moveable-ne,
    .moveable-control.moveable-reverse .moveable-direction.moveable-sw{
        width: 40px;
        height: 40px;
        margin-left: -20px;
        margin-top: -20px;
        background-color: transparent;
        border-color: transparent;
        // background: #ffffff;
        // box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
        &::before{
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background-color: #ffffff;
            box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
            border-radius: 50%;
            margin: auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.moveable-control.moveable-direction.moveable-ne,
.moveable-control.moveable-direction.moveable-sw,
.moveable-control.rCSckyn7i.moveable-reverse .moveable-direction.moveable-nw,
moveable-reverse .moveable-direction.moveable-se{
    width: 24px;
    height: 24px;
    margin-left: -12px;
    margin-top: -12px;
    background: transparent;
    border-color: transparent;
    &::before{
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
        border-radius: 50%;
        margin: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}
@media (hover: none) and (any-pointer: coarse){
    .moveable-control.moveable-direction.moveable-ne,
    .moveable-control.moveable-direction.moveable-sw,
    .moveable-control.rCSckyn7i.moveable-reverse .moveable-direction.moveable-nw,
    moveable-reverse .moveable-direction.moveable-se{
        width: 40px;
        height: 40px;
        margin-left: -20px;
        margin-top: -20px;
        background-color: transparent;
        border-color: transparent;
        // background: #ffffff;
        // box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
        &::before{
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background-color: #ffffff;
            box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
            border-radius: 50%;
            margin: auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.moveable-rotation .moveable-control.moveable-rotation-control{
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
    overflow: visible;
    background-color: transparent !important;
    color: #333333;
    border-width: 0;
    z-index: 12;
    // box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
    &::before{
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background-color: #ffffff;
        background-color: rgba(255, 255, 255, .9);
                backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
        border-radius: 50%;
        margin: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("/images/rotation_icon.svg") !important;  /* アイコンのパスを指定 */
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 0;
    }
    // z-index: 4011;
}
body .moveable-control-box .moveable-rotation{
    height: calc(34px * var(--zoom));
    // height: calc(30px);
}
@media (hover: none) and (any-pointer: coarse){
    .moveable-rotation .moveable-control.moveable-rotation-control{
        width: 40px;
        height: 40px;
        margin-left: -20px;
        margin-top: -20px;
        background-color: transparent;
        &::before{
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            background-color: #ffffff;
            box-shadow: 0 0 4px 1px rgba(57, 76, 96, .15), 0 0 0 1px rgba(43, 59, 74, .3);
            border-radius: 50%;
            margin: auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        // z-index: 4011;
    }
    body .moveable-control-box .moveable-rotation{
        height: calc(34px * var(--zoom));
    }
}

// https://github.com/daybrush/moveable/issues/855
// https://github.com/daybrush/moveable/issues/980
// https://github.com/daybrush/moveable/issues/1083
.moveable-size-value.moveable-gap{
    transform: translateX(-50%) scale(1.5) !important;
    // font-size: 24px !important;
}

// support tool resize handle
.supportToolMoveable .moveable-control.moveable-direction{
    &.moveable-nw,
    &.moveable-ne,
    &.moveable-se,
    &.moveable-sw{
        &::before{
            width: 8px;
            height: 8px;
            border-radius: 2px;
            // border-width: 0;
            // box-shadow: none;
        }
    }
}
.supportToolMoveable .moveable-control.moveable-direction{
    &.moveable-s,
    &.moveable-n{
        background: transparent;
        box-shadow: none;
        border-width: 0;
        &::before{
            content: "";
            display: none;
            width: 32px;
            height: 8px;
            background-color: #ffffff;
            border-radius: 2px;
            margin: auto;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
        }
    }
}
.supportToolMoveable .moveable-control.moveable-direction{
    &.moveable-e,
    &.moveable-w{
        background: transparent;
        box-shadow: none;
        border-width: 0;
        &::before{
            content: "";
            display: none;
            width: 8px;
            height: 32px;
            background-color: #ffffff;
            border-radius: 2px;
            margin-right: 2px;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
        }
    }
}
.supportToolMoveable .moveable-line:not(:root){
    background: transparent;
}
// .supportToolMoveable .moveable-control.moveable-direction.moveable-nw::before{
//     width: 8px;
//     height: 8px;
//     border-radius: 2px;
//     // width: 16px;
//     // height: 16px;
//     // border-radius: 0px;
//     // border-top-width: 4px;
//     // border-left-width: 4px;
//     // border-right-width: 0;
//     // border-bottom-width: 0;
//     // border-color: #cccccc;
//     // border-style: solid;
//     // background-color: transparent;
//     // box-shadow: none;
// }



/* pos guidelines */
.moveable-normal.red{
    background: red !important;
}

/* gap guidelines */
.moveable-gap.red{
    background: red !important;
}

/* When snapped to an element in elementGuidelines */
.moveable-bold.red{
    background: red !important;
}

/* A dashed line between target and element */
.moveable-dashed.red{
    border-top-color: red !important;
    border-left-color: red !important;
}

/* pos guidelines */
.moveable-normal.green{
    background: green !important;
}

/* gap guidelines */
.moveable-gap.green{
    background: green !important;
}

/* When snapped to an element in elementGuidelines */
.moveable-bold.green{
    background: green !important;
}

/* A dashed line between target and element */
.moveable-dashed.green{
    border-top-color: green !important;
    border-left-color: green !important;
}

// cropper
:root{
    --cropper-point-size: 24px; /* ポイントのサイズ */
    @media (hover: none) and (any-pointer: coarse){
        --cropper-point-size: 32px; /* ポイントのサイズ */
    }
    --cropper-point-offset: calc(var(--cropper-point-size) / -2); /* 四隅のポイント用 */
    --cropper-point-margin: calc(var(--cropper-point-size) / -2); /* 中間ポイント用 */
}

.cropper-point{
    height: var(--cropper-point-size) !important;
    width: var(--cropper-point-size) !important;
    border-radius: 4px;
}

:root{
    /* 四隅のポイント */
    .cropper-point.point-nw{
        cursor: nwse-resize;
        left: var(--cropper-point-offset);
        top: var(--cropper-point-offset);
    }

    .cropper-point.point-ne{
        cursor: nesw-resize;
        right: var(--cropper-point-offset);
        top: var(--cropper-point-offset);
    }

    .cropper-point.point-sw{
        cursor: nesw-resize;
        left: var(--cropper-point-offset);
        bottom: var(--cropper-point-offset);
    }

    .cropper-point.point-se{
        cursor: nwse-resize;
        right: var(--cropper-point-offset);
        bottom: var(--cropper-point-offset);
    }

    /* 中間のポイント */
    .cropper-point.point-n,
    .cropper-point.point-s{
        cursor: ns-resize;
        left: 50%;
        margin-left: calc(var(--cropper-point-size) / -2);
    }

    .cropper-point.point-n{
        top: var(--cropper-point-offset);
    }

    .cropper-point.point-s{
        bottom: var(--cropper-point-offset);
    }

    .cropper-point.point-e,
    .cropper-point.point-w{
        cursor: ew-resize;
        top: 50%;
        margin-top: calc(var(--cropper-point-size) / -2);
    }

    .cropper-point.point-e{
        right: var(--cropper-point-offset);
    }

    .cropper-point.point-w{
        left: var(--cropper-point-offset);
    }
}

// canvas
.eraser-bg{
    cursor: url("/images/icons/eraser_icon.svg")0 16, auto;
}
.free-line-bg{
    cursor: url("/images/icons/pen_icon.svg")0 16, auto;
}

// マークダウン
/* Markdown table styling */
.cc-markdown table{
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    border-color: inherit !important;
    display: block !important;
    width: 100% !important;
    max-width: 100% !important;
    overflow: auto !important;
    margin-top: 8px;
    margin-bottom: 8px;
}

.cc-markdown tbody,
.cc-markdown td,
.cc-markdown tfoot,
.cc-markdown th,
.cc-markdown thead,
.cc-markdown tr{
    border-color: inherit !important;
    border-style: solid !important;
    border-width: 2px !important;
    padding: .5rem;
    background-color: #ffffff;
}